import { FC, Fragment, useMemo } from "react";

import { useIntl } from "react-intl";

import { Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { colors, muiTheme } from "@mb-pro-ui/components";

import { VictoryChart, VictoryLine, VictoryAxis, VictoryTheme } from "victory";

import { getTimeTick } from "../../utils/date";

const useStyles = makeStyles((theme: Theme) => ({
  chart: {
    width: "80%",
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}));

interface FridgeChartProps {
  channels: {
    name: string;
    meas: {
      x: string;
      y: number;
    }[];
  }[];
  display: number;
}

const FridgeChart: FC<FridgeChartProps> = ({ channels, display }) => {
  const classes = useStyles(muiTheme);
  const intl = useIntl();

  const dataSets = useMemo(
    () =>
      channels.map((channel) => ({
        name: channel.name,
        meas: channel.meas?.map((mea) => ({
          x: new Date(mea.x),
          y: mea.y,
        })),
      })),
    [channels]
  );

  return (
    <Fragment>
      <div className={classes.chart}>
        <VictoryChart scale={{ x: "time" }} height={200} padding={40}>
          <VictoryAxis
            scale="time"
            tickCount={3}
            tickFormat={(e) => getTimeTick(e, intl.locale)}
          />
          <VictoryAxis dependentAxis />
          <VictoryLine
            data={dataSets[display].meas}
            theme={VictoryTheme.material}
            style={{
              data: { stroke: colors.highlight },
            }}
          />
        </VictoryChart>
      </div>
    </Fragment>
  );
};

export default FridgeChart;
