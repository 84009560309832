import { useRef, useEffect } from "react";

export default function useOnChange<T>(
  currentValue: T,
  onChange: (current: T, prev: T | undefined) => void
) {
  const ref = useRef<T | undefined>();
  useEffect(() => {
    if (ref.current !== currentValue) {
      onChange(currentValue, ref.current);
      ref.current = currentValue;
    }
  }, [currentValue, onChange]);
}
