import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { IntlProvider } from "react-intl";

import DashBoard from "./components/Dashboard";

import { ThemeProvider } from "@material-ui/core";

import { muiTheme } from "@mb-pro-ui/components";

import { QueryClient, QueryClientProvider } from "react-query";

import Details from "./components/Details";
import Edit from "./components/Edit";
import NewFridge from "./components/NewFridge";

//Languages
//import huMessages from "./lang/hu.json";
import enMessages from "./lang/en.json";

export const queryClient = new QueryClient();

function App() {
  return (
    <IntlProvider locale="en" messages={enMessages} defaultLocale="en">
      <ThemeProvider theme={muiTheme}>
        <QueryClientProvider client={queryClient}>
          <Router basename="/fridge/">
            <Switch>
              <Route path="/fridges/new">
                <NewFridge />
              </Route>
              <Route path="/fridges/edit/:id">
                <Edit />
              </Route>
              <Route path="/fridges/:id">
                <Details />
              </Route>
              <Route path={["/fridges", "/"]}>
                <DashBoard />
              </Route>
            </Switch>
          </Router>
        </QueryClientProvider>
      </ThemeProvider>
    </IntlProvider>
  );
}

export default App;
