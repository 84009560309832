import { FC } from "react";

import {
  makeStyles,
  Drawer,
  Grid,
  Typography,
  TextField,
  List,
  ListItem,
  Slider,
  Theme,
  DrawerProps,
  Hidden,
  fade,
} from "@material-ui/core";

import { useIntl, FormattedMessage } from "react-intl";

import ErrorIcon from "@material-ui/icons/Error";

import { useSearchParams } from "../../hooks/useSearchParams";

import { DetailChannel } from "../Details";

import { MeasResolution, formatUnit } from "../../utils/api";

const useStyles = makeStyles((theme: Theme) => ({
  flex_right: {
    flex: "1",

    backgroundColor: theme.palette.background.default,

    boxShadow: " 0  0 8px 4px rgba(0,0,0,0.2)",
    transition: " 0.3s",

    paddingTop: "2%",
    paddingLeft: "3%",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      height: "40vh",
    },
  },
  drawer: {
    width: "20vw",

    zIndex: 0,

    paddingTop: "6.2vh",
    height: "100vh",

    [theme.breakpoints.down("md")]: {
      width: "100vw",
      marginTop: "0",
      paddingTop: "0",
      height: "fit-content",
    },
  },

  drawer_header: {
    display: "flex",
    alignItems: "center",
    marginTop: "5%",
    [theme.breakpoints.down("md")]: {
      marginTop: "1%",
      maxWidth: "fit-content",
    },
  },
  drawer_title: {
    fontSize: "1.6rem",
  },

  drawer_datepicker: {
    marginTop: "15%",
    [theme.breakpoints.down("md")]: {
      marginTop: "1%",
      paddingLeft: "10%",
      maxWidth: "fit-content",
    },
    [theme.breakpoints.between("sm", "md")]: {
      display: "flex",
    },
  },
  drawer_channels: {
    marginTop: "15%",
    [theme.breakpoints.down("md")]: {
      marginTop: "1%",
      maxWidth: "fit-content",
    },
  },
  drawer_slider: {
    marginTop: "10%",
    marginLeft: "5%",
    maxWidth: "fit-content(80%)",
    [theme.breakpoints.down("md")]: {
      marginTop: "1%",
    },
  },

  slider: {
    width: "80%",
  },
  date_pickers: {
    marginTop: "2%",
    marginRight: "5%",
    [theme.breakpoints.down("md")]: {
      marginTop: "3px",
    },
  },

  chosen_list_item: {
    backgroundColor: fade(theme.palette.primary.light, 0.15),
    borderLeft: `3px solid ${theme.palette.primary.light}`,
    width: "fit-content",
  },
  details_list: {
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.down("md")]: {
      flexDirection: "row",
      justifyContent: "flex-start",
      alignItems: "start",
    },
  },

  error_icon: {
    color: theme.palette.error.main,
    fontSize: "2rem",
    marginLeft: "5%",
  },
}));

const MeasMarks: { value: number; label: MeasResolution }[] = [
  { value: 0, label: "30m" },
  { value: 1, label: "5m" },
  { value: 2, label: "1m" },
  { value: 3, label: "30s" },
  { value: 4, label: "5s" },
];

interface DetailsDrawerProps {
  anchor: DrawerProps["anchor"];
  fridgeData: DetailChannel[];
  from: string;
  to: string;
  changeFrom: (from: string) => void;
  changeTo: (to: string) => void;
  active: number;
  setActive: (active: number) => void;
  mea: MeasResolution;
}

const DetailsDrawer: FC<DetailsDrawerProps> = ({
  anchor,
  fridgeData,
  to,
  from,
  changeTo,
  changeFrom,
  active,
  setActive,
  mea,
}) => {
  const classes = useStyles();

  const intl = useIntl();

  const [, setSearchParams] = useSearchParams();

  return (
    <Drawer
      variant="persistent"
      anchor={anchor}
      open={true}
      classes={{ paper: classes.drawer }}
    >
      <Grid
        className={classes.flex_right}
        alignContent="flex-start"
        alignItems="center"
        justify="center"
        spacing={0}
        container
      >
        <Grid
          className={classes.drawer_header}
          lg={12}
          md={6}
          sm={6}
          xs={6}
          item
        >
          {fridgeData.length !== 0 ? (
            <>
              <Typography variant="subtitle1" className={classes.drawer_title}>
                <FormattedMessage id="lastvalue" defaultMessage="Last value" />
                {": "}
                {formatUnit(
                  fridgeData[active].last_value,
                  fridgeData[active].unit,
                  intl
                )}
              </Typography>
              {fridgeData[active].alert ? (
                <ErrorIcon className={classes.error_icon} />
              ) : null}
            </>
          ) : null}
        </Grid>
        <Grid
          className={classes.drawer_datepicker}
          lg={12}
          md={6}
          sm={6}
          xs={6}
          item
        >
          <TextField
            id="datetime-local"
            label={intl.formatMessage({ id: "from", defaultMessage: "From" })}
            type="datetime-local"
            value={from}
            onChange={(e) => changeFrom(e.target.value)}
            className={classes.date_pickers}
            inputProps={{
              className: classes.date_pickers,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <TextField
            id="datetime-local"
            label={intl.formatMessage({ id: "to", defaultMessage: "To" })}
            type="datetime-local"
            value={to}
            onChange={(e) => changeTo(e.target.value)}
            className={classes.date_pickers}
            inputProps={{
              className: classes.date_pickers,
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid
          className={classes.drawer_channels}
          lg={12}
          md={6}
          sm={6}
          xs={12}
          item
        >
          <Typography variant="subtitle2">
            <FormattedMessage id="channels" defaultMessage="Channels" />
          </Typography>
          <List className={classes.details_list}>
            {fridgeData.map((channel, i) => (
              <ListItem
                key={i}
                className={`${i === active ? classes.chosen_list_item : null}`}
                onClick={() => {
                  setSearchParams({ act: i.toString() });
                  setActive(i);
                }}
              >
                {channel.name}
              </ListItem>
            ))}
          </List>
        </Grid>
        <Hidden xsDown>
          <Grid
            className={classes.drawer_slider}
            lg={12}
            md={6}
            sm={6}
            xs={12}
            item
          >
            <Typography variant="subtitle2">
              <FormattedMessage id="meas" defaultMessage="Measurements" />
            </Typography>
            <Slider
              disabled
              className={classes.slider}
              value={MeasMarks.find((mark) => mark.label === mea)?.value}
              min={0}
              max={4}
              step={1}
              marks={MeasMarks}
            />
          </Grid>
        </Hidden>
      </Grid>
    </Drawer>
  );
};

export default DetailsDrawer;
