import { useEffect } from "react";
import { FC } from "react";

import { FormState } from "final-form";
import { FormSpy } from "react-final-form";

interface FridgeFormState
  extends FormState<Record<string, any>, Partial<Record<string, any>>> {
  handleFunction?: (
    newFormState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) => void;
}

const DataHandler: FC<FridgeFormState> = (props) => {
  const { handleFunction, ...restProps } = props;

  useEffect(() => {
    if (handleFunction) handleFunction(restProps);
  }, [restProps, handleFunction]);

  return <></>;
};

interface FridgeFormSpyProps {
  handleFunction?: (
    newFormState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) => void;
}

const FridgeFormSpy = (props: FridgeFormSpyProps) => {
  return <FormSpy {...props} component={DataHandler} />;
};

export default FridgeFormSpy;
