import { FC, useState, useRef } from "react";

import { useIntl } from "react-intl";

import { useDebounce } from "../hooks/useDebounce";

import { makeStyles, Theme, Hidden } from "@material-ui/core";

import { useQuery } from "react-query";

import { useParams, useHistory } from "react-router-dom";

import { FormHeader, muiTheme } from "@mb-pro-ui/components";

import { DomainTuple } from "victory";

import { FridgeWChannels, MeasResolution } from "../utils/api";

import { searchFridgeById } from "../utils/api";
import DetailedChart from "./details/DetailedChart";
import { useSearchParams } from "../hooks/useSearchParams";
import DetailsDrawer from "./details/DetailsDrawer";

const useStyles = makeStyles((theme: Theme) => ({
  app_bar: {
    zIndex: theme.zIndex.drawer + 1,
    position: "relative",
  },
  page: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  },
  container: {
    minHeight: "94vh",
    display: "flex",
    width: "100vw",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  flex_left: {
    flex: "5",
    height: "93vh",
    width: "100%",
    marginRight: "20vw",
    [theme.breakpoints.down("md")]: {
      marginRight: "0",
      maxHeight: "70vh",
    },
    [theme.breakpoints.down("xs")]: {
      marginRight: "0",
      maxHeight: "50vh",
    },
  },
}));

export interface DetailChannel {
  id: number;
  name: string;
  alert: boolean;
  unit: string;
  alert_ts: Date;
  last_value: number;
  entireDomain: {
    x: DomainTuple;
    y: DomainTuple;
  };
}
let defaultChannel: DetailChannel[] = [
  {
    id: 1,
    name: "Loading...",
    alert: false,
    alert_ts: new Date(Date.now()),
    last_value: -1,
    unit: "Celsius",
    entireDomain: {
      x: [0, 1],
      y: [0, 1],
    },
  },
  {
    id: 2,
    name: "Loading...",
    alert: false,
    alert_ts: new Date(Date.now()),
    last_value: -1,
    unit: "%",
    entireDomain: {
      x: [0, 1],
      y: [0, 1],
    },
  },
];

interface UrlParams {
  id: string;
}

const Details: FC = () => {
  const classes = useStyles(muiTheme);
  const intl = useIntl();

  const history = useHistory();

  let { id } = useParams<UrlParams>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [active, setActive] = useState(
    parseInt(searchParams.get("act") ?? "0")
  );

  const [mea, setMea] = useState<MeasResolution>("30m" as MeasResolution);

  const [from, setFrom] = useState(
    searchParams.get("from") ?? "2021-02-09T12:00"
  );
  const debouncedFrom = useDebounce(from, 500);

  const [to, setTo] = useState(searchParams.get("to") ?? "2021-02-10T12:00");
  const debouncedTo = useDebounce(to, 500);

  const { data, isFetched } = useQuery<
    FridgeWChannels,
    unknown,
    DetailChannel[]
  >(
    ["fridgeData", id, debouncedFrom, debouncedTo],
    () => searchFridgeById(parseInt(id), debouncedFrom, debouncedTo, mea),
    {
      select: (res) => {
        return res.channels.map(
          ({
            id,
            name,
            data_channels: { alert, alert_ts, last_value, meas },
            unit,
          }) => ({
            id,
            name,
            alert,
            last_value,
            unit: unit ?? "",
            alert_ts: new Date(alert_ts || 0),
            entireDomain: {
              x: [
                new Date(meas[meas.length - 1].x),
                new Date(meas[0].x),
              ] as DomainTuple,
              y: [
                meas.reduce(
                  (min, mea) => (mea.y < min ? mea.y : min),
                  meas[0].y
                ) / 1.01,
                meas.reduce(
                  (max, mea) => (mea.y > max ? mea.y : max),
                  meas[0].y
                ) * 1.01,
              ] as DomainTuple,
            },
          })
        );
      },
    }
  );

  const fridgeData = data ?? defaultChannel;

  const parentRef = useRef(null);

  const changeFrom = (from: string) => {
    if (new Date(from) < new Date(to)) {
      setFrom(from);
      setSearchParams({ from });
    }
  };

  const changeTo = (to: string) => {
    if (new Date(from) < new Date(to)) {
      setTo(to);
      setSearchParams({ to });
    }
  };

  const handleMeaChange = (newMea: MeasResolution) => {
    if (newMea !== mea) setMea(newMea);
  };

  return (
    <div className={classes.page}>
      <FormHeader
        className={classes.app_bar}
        title={intl.formatMessage({ id: "fridge", defaultMessage: "Fridge" })}
        links={[]}
        hasBack={true}
        hasSave={false}
        onClickBack={() => {
          history.push("/fridges");
        }}
      />
      <div className={classes.container}>
        <div className={classes.flex_left} ref={parentRef}>
          {isFetched && fridgeData.length !== 0 ? (
            <DetailedChart
              parent={parentRef}
              channel={fridgeData[active]}
              unit={fridgeData[active].unit}
              from={new Date(from)}
              to={new Date(to)}
              handleMeaChange={handleMeaChange}
            />
          ) : null}
        </div>
      </div>
      <Hidden mdDown>
        <DetailsDrawer
          anchor="right"
          fridgeData={fridgeData}
          from={from}
          to={to}
          changeFrom={changeFrom}
          changeTo={changeTo}
          active={active}
          setActive={setActive}
          mea={mea}
        />
      </Hidden>
      <Hidden lgUp>
        <DetailsDrawer
          anchor="bottom"
          fridgeData={fridgeData}
          from={from}
          to={to}
          changeFrom={changeFrom}
          changeTo={changeTo}
          active={active}
          setActive={setActive}
          mea={mea}
        />
      </Hidden>
    </div>
  );
};

export default Details;
