import { IntlShape } from "react-intl";

const apiUrl = process.env.NODE_ENV === "production" ? "/fridge-api/" : "/";

export type MeasResolution = "5s" | "30s" | "1m" | "5m" | "30m";

export interface Mea {
  x: string;
  y: number;
}

interface Channel {
  id: number;
  name: string;
  unit: string;
}

interface ChannelWData extends Channel {
  data_channels: {
    account: string;
    tag: string;
    alert: boolean;
    last_value: number;
    alert_ts?: number;
    meas: Mea[];
  };
}

interface Fridge {
  id: number;
  account: string;
  name: string;
}

export interface FridgeWChannels extends Fridge {
  channels: ChannelWData[];
}

//Queries
const searchFridgeByIdQuery = (
  id: number,
  from: string | null,
  to: string | null,
  measRes: MeasResolution
) =>
  `fridges?select=id,account,name,channels:fridge_channels(id:channel_ref,name,unit,data_channels(alert,alert_ts,last_value,tag${
    to || from ? `,meas:meas_${measRes}(x:ts,y:avg)` : ""
  }))&id=eq.${id}${from ? `&channels.data_channels.meas.ts=gte.${from}` : ""}${
    to ? `&channels.data_channels.meas.ts=lte.${to}` : ""
  }${to || from ? "&channels.data_channels.meas.order=ts.desc" : ""}`;

const searchFridgeQuery = (
  acc: string | null,
  name: string | null,
  measRes: MeasResolution
) =>
  `fridges?select=id,account,name,channels:fridge_channels(id:channel_ref,name,unit,data_channels(alert,alert_ts,last_value,tag,meas:meas${
    measRes ? "_" + measRes : ""
  }(x:ts,y:avg)))${acc ? "&account=like.*" + acc + "*" : ""}${
    name ? "&name=like.*" + name + "*" : ""
  }&channels.data_channels.meas.limit=100&channels.data_channels.meas.order=ts.desc`;

const searchMeasByChannelQuery = (
  id: number,
  from: string,
  to: string,
  measRes: MeasResolution
) =>
  `meas_${measRes}?select=x:ts,y:avg&channel_ref=eq.${id}&ts=gte.${from}&ts=lte.${to}&order=ts`;

//Api fetch functions
export const searchFridges = async (
  acc: string | null,
  name: string | null,
  measRes: MeasResolution = "30m"
): Promise<FridgeWChannels[]> => {
  if (name) {
    name = name.replaceAll(" ", "*").replaceAll("#", "*");
  }

  const data = await fetch(`${apiUrl}${searchFridgeQuery(acc, name, measRes)}`);
  const fridges = await data.json();

  return fridges;
};

export const searchFridgeById = async (
  id: number,
  from: string | null,
  to: string | null,
  measRes: MeasResolution = "30m"
): Promise<FridgeWChannels> => {
  const data = await fetch(
    `${apiUrl}${searchFridgeByIdQuery(id, from, to, measRes)}`
  );
  const fridgeData = await data.json();

  return fridgeData[0];
};

export const searchMeasByChannel = async (
  id: number,
  from: string,
  to: string,
  measRes: MeasResolution = "30m"
): Promise<Mea[]> => {
  const data = await fetch(
    `${apiUrl}${searchMeasByChannelQuery(id, from, to, measRes)}`
  );
  const meas = await data.json();

  return meas;
};

export const getChannels = async (): Promise<
  {
    id: number;
    account: string;
    tag: string;
  }[]
> => {
  const data = await fetch(`${apiUrl}data_channels?select=id,account,tag`);

  return data.json();
};

export interface AddedFridge {
  name: string;
  account: string;
  newchannels?: {
    newname: string;
    channel_ref: number;
    unit: string;
  }[];
}

export const saveNewFridge = async (fridge: AddedFridge, intl: IntlShape) => {
  const { name, account, newchannels } = fridge;
  const body = JSON.stringify({
    name,
    account,
  });

  try {
    let req = await fetch(`${apiUrl}fridges`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Prefer: "return=representation",
      },
      body,
    });

    if (req.status === 409)
      return [
        -1,
        intl.formatMessage({
          id: "accconflict",
          defaultMessage: "Account name already taken",
        }),
      ];

    const fridges = await req.json();
    const { id } = fridges[0] ?? -1;

    if (newchannels) {
      const fridge_channels = JSON.stringify(
        newchannels.map((channel) => ({
          fridge_ref: id,
          ...channel,
        }))
      );

      await fetch(`${apiUrl}fridge_channels`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: fridge_channels,
      });
    }

    return [id, ""];
  } catch (e) {}
  return [-1, ""];
};

export interface EditedFridge {
  name?: string;
  newchannels?: {
    newname: string;
    channel_ref: number;
    unit: string;
  }[];
}

export const saveEditedFridge = async (
  id: number,
  fridge: EditedFridge,
  initialFridge: EditedFridge
) => {
  const { name } = fridge;

  const editedNewChannels =
    fridge.newchannels?.map((channel) => ({
      fridge_ref: id,
      ...channel,
    })) ?? [];
  const initialNewChannels =
    initialFridge.newchannels?.map((channel) => ({
      fridge_ref: id,
      ...channel,
    })) ?? [];

  const body = JSON.stringify({
    name,
  });

  if (name) {
    await fetch(`${apiUrl}fridges?id=eq.${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
        Prefer: "return=representation",
      },
      body,
    });
  }

  const added = editedNewChannels.filter(
    (channel) =>
      !initialNewChannels.some(
        (initial) =>
          initial.newname === channel.newname &&
          initial.channel_ref === channel.channel_ref &&
          initial.unit === channel.unit
      )
  );
  const deleted = initialNewChannels.filter(
    (channel) =>
      !editedNewChannels.some(
        (initial) =>
          initial.newname === channel.newname &&
          initial.channel_ref === channel.channel_ref &&
          initial.unit === channel.unit
      )
  );

  if (deleted.length > 0) {
    await fetch(
      `${apiUrl}fridge_channels?fridge_ref=eq.${id}&channel_ref=in.(${deleted.map(
        (f) => `${f.channel_ref}`
      )})`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Prefer: "return=representation",
        },
        body: JSON.stringify(added),
      }
    );
  }

  if (added.length > 0) {
    await fetch(`${apiUrl}fridge_channels`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Prefer: "return=representation",
      },
      body: JSON.stringify(added),
    });
  }
};

export const deleteFridge = async (id: number) => {
  await fetch(`${apiUrl}fridges?id=eq.${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const formatUnit = (
  value: number | null,
  unit: string | null,
  intl: IntlShape
) => {
  const strValue = value !== null ? intl.formatNumber(value) : "";

  switch (unit) {
    case null:
      return strValue;
    case "Celsius":
      return `${strValue} °C`.trimLeft();
    default:
      return `${strValue} ${unit}`.trimLeft();
  }
};
