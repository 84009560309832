import {
  TextField,
  Select,
  Checkbox,
  InjectedOptionProps,
  SelectOption as Option,
} from "@mb-pro-ui/components";

import { FieldInputProps, FieldMetaState } from "react-final-form";

import clsx from "clsx";

interface SelectOption extends Option {
  label?: string;
}

export const TextFieldAdapter = ({
  input,
  meta,
  required,
  className,
  ...rest
}: {
  input: FieldInputProps<string>;
  meta: FieldMetaState<string>;
  error: boolean;
  required: boolean | undefined;
  className?: string;
}) => (
  <TextField
    {...input}
    {...rest}
    className={clsx(className, meta.dirty && "Mbp-dirty")}
    error={(required && !input.value && meta.touched) || !!meta.submitError}
  />
);

export const SelectAdapter = ({
  input,
  render,
  items,
  required,
  error,
  meta,
  className,
  ...rest
}: {
  input: FieldInputProps<string>;
  render: (props: InjectedOptionProps) => JSX.Element | JSX.Element[] | string;
  items: SelectOption[];
  required: boolean;
  error?: boolean;
  meta: FieldMetaState<string>;
  className?: string;
  option: SelectOption;
}) => {
  return (
    <Select
      {...input}
      {...rest}
      items={items}
      className={clsx(className, meta.dirty && "Mbp-dirty")}
      error={(required && !input.value && meta.touched) || !!meta.submitError}
    >
      {render}
    </Select>
  );
};

export const CheckBoxAdapter = ({
  input,
  meta,
  className,
  ...rest
}: {
  input: FieldInputProps<boolean>;
  meta: FieldMetaState<boolean>;
  className?: string;
}) => (
  <Checkbox
    className={clsx(className, meta.dirty && "Mbp-dirty")}
    {...input}
    {...rest}
  />
);
