import { FC, useEffect } from "react";

import FridgeFormSpy from "./FridgeFormSpy";

import { useIntl } from "react-intl";

import { muiTheme } from "@mb-pro-ui/components";

import { FormState } from "final-form";
import { Form, Field, AnyObject } from "react-final-form";
import arrayMutators from "final-form-arrays";
import { FieldArray } from "react-final-form-arrays";

import {
  Paper,
  IconButton,
  makeStyles,
  Theme,
  Typography,
} from "@material-ui/core";

import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";

import { TextFieldAdapter, SelectAdapter } from "../../utils/adapters";

interface Values {
  name?: string;
  account?: string;
  newchannels?: {
    name?: string;
    unit?: string;
    channel_ref?: number;
  }[];
}

interface Data {
  id: number;
  account: string;
  tag: string;
}

interface FridgeFormProps {
  onSubmit: (values: Values) => Promise<Values>;
  setSubmit: (
    newSubmit:
      | ((
          event?: Partial<
            Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">
          >
        ) => Promise<AnyObject | undefined> | undefined | void)
      | undefined
  ) => void;

  channelsData: Data[] | undefined;
  disabledFields?: string[];
  initialValues?: {
    name?: string;
    account?: string;
    newchannels?: {
      name?: string;
      unit?: string;
      channel_ref?: number;
    }[];
  };
  getFormState?: (
    newFormState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  paper: {
    height: "fit-content",
    width: "fit-content",

    marginTop: "15%",
    marginLeft: "5%",
    [theme.breakpoints.down("xs")]: {
      marginTop: "25%",
    },
  },
  form: {
    margin: "auto",
    marginTop: "10%",

    padding: "3%",

    display: "flex",

    flexDirection: "column",
  },
  form_left: {
    marginRight: "2%",
    display: "flex",

    flexDirection: "column",
  },
  form_fields: {
    display: "flex",
    alignItems: "baseline",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  inputs: {
    marginTop: "2%",
    marginLeft: "2%",
  },
  new_channel: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  form_buttons: {
    display: "flex",
    alignItems: "center",
    marginLeft: "2%",
  },
  delete_icon: {
    color: theme.palette.error.main,
  },
}));

const FridgeForm: FC<FridgeFormProps> = ({
  channelsData,
  onSubmit,
  setSubmit,
  initialValues,
  disabledFields,
  getFormState,
}) => {
  const classes = useStyles(muiTheme);
  const intl = useIntl();

  let submit:
    | ((
        event?: Partial<
          Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">
        >
      ) => Promise<AnyObject | undefined> | undefined)
    | undefined = undefined;

  useEffect(() => {
    setSubmit((e) => {
      if (submit) submit(e);
    });
  }, [setSubmit, submit]);

  return (
    <Paper className={classes.paper}>
      <Form
        onSubmit={onSubmit}
        mutators={{
          ...arrayMutators,
        }}
        initialValues={initialValues}
        render={({
          handleSubmit,
          form: {
            mutators: { push },
          },
        }) => {
          submit = handleSubmit;

          return (
            <>
              <form onSubmit={handleSubmit} className={classes.form}>
                <div className={classes.form_left}>
                  <div className={classes.form_fields}>
                    <Field
                      name="name"
                      label={intl.formatMessage({
                        id: "name",
                        defaultMessage: "Name",
                      })}
                      className={classes.inputs}
                      component={TextFieldAdapter}
                      type="text"
                      required
                    />
                    <Field
                      name="account"
                      className={classes.inputs}
                      label={intl.formatMessage({
                        id: "account",
                        defaultMessage: "Account",
                      })}
                      component={TextFieldAdapter}
                      type="text"
                      disabled={disabledFields?.includes("account")}
                      required
                    />
                  </div>
                  <div className={classes.form_buttons}>
                    <Typography>Channels:</Typography>
                    <IconButton onClick={() => push("newchannels", undefined)}>
                      <AddIcon />
                    </IconButton>
                  </div>
                </div>
                <div>
                  <FieldArray name="newchannels">
                    {({ fields }) =>
                      fields.map((name, index) => {
                        return (
                          <div key={index} className={classes.new_channel}>
                            <Field
                              name={`${name}.name`}
                              label={intl.formatMessage({
                                id: "name",
                                defaultMessage: "Name",
                              })}
                              component={TextFieldAdapter}
                              required
                            />
                            <Field
                              name={`${name}.unit`}
                              label={intl.formatMessage({
                                id: "unit",
                                defaultMessage: "Unit",
                              })}
                              component={SelectAdapter}
                              items={[
                                { value: "%", label: "%" },
                                { value: "Celsius", label: "°C" },
                              ]}
                              render={({ option }) => (
                                <span>{option.label}</span>
                              )}
                              required
                            />
                            <Field
                              name={`${name}.channel_ref`}
                              label={intl.formatMessage({
                                id: "source",
                                defaultMessage: "Source",
                              })}
                              component={SelectAdapter}
                              items={
                                channelsData
                                  ?.filter(
                                    (channel) =>
                                      fields?.value.filter(
                                        (e) => e?.channel_ref === channel.id
                                      ).length === 0 ||
                                      fields.value[index]?.channel_ref ===
                                        channel.id
                                  )
                                  .map((channel) => ({
                                    value: channel.id,
                                    label: `${channel.account}  ${channel.tag}`,
                                  })) ?? []
                              }
                              render={({ option }) => (
                                <span>{option.label}</span>
                              )}
                              required
                            />

                            <IconButton
                              onClick={() => fields.remove(index)}
                              className={classes.delete_icon}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        );
                      })
                    }
                  </FieldArray>
                </div>
                <FridgeFormSpy handleFunction={getFormState} />
              </form>
            </>
          );
        }}
      />
    </Paper>
  );
};

export default FridgeForm;
