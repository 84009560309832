import { FC, useEffect, useMemo, useState } from "react";

import { useIntl } from "react-intl";

import {
  VictoryChart,
  VictoryLine,
  VictoryAxis,
  VictoryTheme,
  VictoryLabel,
  VictoryTooltip,
  createContainer,
  VictoryZoomContainerProps,
  VictoryVoronoiContainerProps,
  DomainTuple,
} from "victory";

import { useQuery } from "react-query";

import { queryClient } from "../../App";

import {
  searchMeasByChannel,
  MeasResolution,
  Mea,
  formatUnit,
} from "../../utils/api";
import useOnChange from "../../hooks/useOnChange";

import { getTimeTick } from "../../utils/date";

const maxPoints = 300;

const maxTimes: { mea: MeasResolution; maxTime: Number }[] = [
  { mea: "30m", maxTime: 1800 * maxPoints },
  { mea: "5m", maxTime: 300 * maxPoints },
  { mea: "1m", maxTime: 60 * maxPoints },
  { mea: "30s", maxTime: 30 * maxPoints },
  { mea: "5s", maxTime: 5 * maxPoints },
];

const VictoryZoomVoronoiContainer = createContainer<
  VictoryZoomContainerProps,
  VictoryVoronoiContainerProps
>("zoom", "voronoi");

interface DetailedChartProps {
  parent: any;
  channel: {
    id: number;
    name: string;
    alert: boolean;
    alert_ts: Date;
    last_value?: number;
    entireDomain: {
      x: DomainTuple;
      y: DomainTuple;
    };
  };
  from: Date;
  to: Date;
  unit: string;
  handleMeaChange: (mea: MeasResolution) => void;
}

const DetailedChart: FC<DetailedChartProps> = ({
  parent,
  channel,
  unit,
  from,
  to,
  handleMeaChange,
}) => {
  const intl = useIntl();

  const [domain, setDomain] = useState<{ x: DomainTuple; y: DomainTuple }>(
    channel.entireDomain
  );

  useOnChange(channel.id, () => {
    setDomain(channel.entireDomain);
  });

  const mea: MeasResolution = useMemo(() => {
    const currMeaIndex = maxTimes.findIndex(
      ({ maxTime }) =>
        maxTime <
        ((domain.x[1] instanceof Date ? domain.x[1].getTime() : 0) -
          (domain.x[0] instanceof Date ? domain.x[0].getTime() : 0)) /
          1000
    );

    const currentMea =
      currMeaIndex === -1
        ? "30s"
        : maxTimes[currMeaIndex < 1 ? currMeaIndex : currMeaIndex - 1].mea;

    return currentMea;
  }, [domain]);

  useEffect(() => {
    handleMeaChange(mea);
  }, [mea, handleMeaChange]);

  const { data } = useQuery<Mea[], unknown, { x: Date; y: number }[]>(
    ["mea", channel, mea],
    () =>
      searchMeasByChannel(
        channel.id,
        from.toISOString(),
        to.toISOString(),
        mea
      ),
    {
      select: (res) => res.map((mea) => ({ x: new Date(mea.x), y: mea.y })),
      initialData: () =>
        queryClient.getQueryData([
          "mea",
          channel.id,
          maxTimes[maxTimes.findIndex((d) => d.mea === mea) - 1]?.mea,
        ]),
      keepPreviousData: true,
    }
  );

  const getZoomedData = useMemo(() => {
    const zoomedData = data ?? [];

    return zoomedData.filter((d) => d.x >= domain.x[0] && d.x <= domain.x[1]);
  }, [domain.x, data]);

  return (
    <VictoryChart
      theme={VictoryTheme.material}
      scale={{ x: "time" }}
      containerComponent={
        <VictoryZoomVoronoiContainer
          zoomDomain={domain}
          onZoomDomainChange={(domain) => setDomain(domain)}
        />
      }
      height={parent.current?.offsetHeight ?? 300}
      width={parent.current?.offsetWidth ?? 450}
    >
      <VictoryLabel x={400} y={24} text={channel.name} />
      <VictoryLabel x={15} y={30} text={formatUnit(null, unit, intl)} />
      <VictoryAxis
        scale="time"
        tickFormat={(e) => getTimeTick(e, intl.locale)}
      />
      <VictoryAxis standalone={false} dependentAxis />
      {getZoomedData.length > 0 ? (
        <VictoryLine
          domain={channel.entireDomain}
          data={getZoomedData}
          labels={({ datum }: any) =>
            ` ${datum.x.getHours()}:${
              datum.x.getMinutes() < 10 ? "0" : ""
            }${datum.x.getMinutes()} ${formatUnit(datum.y, unit, intl)}`
          }
          standalone={false}
          labelComponent={<VictoryTooltip />}
        />
      ) : null}
    </VictoryChart>
  );
};

export default DetailedChart;
