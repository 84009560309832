import { FC, useMemo, useRef, useState } from "react";

import { FormHeader, muiTheme } from "@mb-pro-ui/components";

import { useParams, useHistory, Prompt } from "react-router-dom";

import { useIntl, FormattedMessage } from "react-intl";

import { FormState } from "final-form";
import { AnyObject } from "react-final-form";

import FridgeForm from "./FridgeForm/FridgeForm";

import { useQuery } from "react-query";

import { makeStyles, Theme, Typography, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import {
  searchFridgeById,
  getChannels,
  saveEditedFridge,
  EditedFridge,
} from "../utils/api";

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
}));

interface Values {
  name?: string;
  newchannels?: {
    name?: string;
    unit?: string;
    channel_ref?: number;
  }[];
}

interface UrlParams {
  id: string;
}

const Edit: FC = () => {
  let submit:
    | ((
        event?: Partial<
          Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">
        >
      ) => Promise<AnyObject | undefined> | undefined | void)
    | undefined = undefined;

  const history = useHistory();

  const classes = useStyles(muiTheme);
  const intl = useIntl();

  const { id } = useParams<UrlParams>();

  const { data, isLoading, refetch } = useQuery(
    `fridge${id}`,
    () => searchFridgeById(parseInt(id), null, null),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const { data: channelsData, isLoading: isChannelsLoading } = useQuery(
    "channels",
    () => getChannels(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const formState = useRef<
    FormState<Record<string, any>, Partial<Record<string, any>>>
  >();

  const [isAlertOpen, setIsAlertOpen] = useState(false);

  const [isDirty, setIsDirty] = useState(false);

  const setFormState = (
    newFormState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) => {
    if (formState.current?.dirty !== newFormState.dirty) {
      setIsDirty(newFormState.dirty);
    }
    formState.current = newFormState;
  };

  const initialValues = useMemo(
    () => ({
      name: data?.name,
      account: data?.account,
      newchannels: data?.channels?.map((channel) => ({
        name: channel.name,
        unit: channel.unit ?? undefined,
        channel_ref: channel.id,
      })),
    }),
    [data]
  );

  const onSubmit = async (values: Values) => {
    let errors: Values = { newchannels: [] };

    if (!values.name) errors.name = "Required";

    values.newchannels?.forEach((channel) => {
      let chanelerror: {
        name?: string;
        unit?: string;
        channel_ref?: number;
      } = {};
      if (!channel?.name) {
        chanelerror.name = "Required";
      }
      if (!channel?.unit) {
        chanelerror.unit = "Required";
      }
      if (!channel?.channel_ref) {
        chanelerror.channel_ref = -1;
      }

      errors.newchannels?.push(chanelerror);
    });

    if (
      Object.keys(errors).length === 1 &&
      errors.newchannels?.every((e) => Object.keys(e).length === 0)
    ) {
      setIsDirty(false);
      await saveEditedFridge(
        parseInt(id),
        values as EditedFridge,
        initialValues as EditedFridge
      );
      refetch();
      setIsAlertOpen(true);
    }

    return errors;
  };

  const changeSubmit = (
    newSubmit:
      | ((
          event?: Partial<
            Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">
          >
        ) => Promise<AnyObject | undefined> | undefined | void)
      | undefined
  ) => {
    submit = newSubmit;
  };

  return (
    <>
      <Prompt
        when={isDirty}
        message={() =>
          intl.formatMessage({
            id: "edit_msg",
            defaultMessage: "You have unsaved data",
          })
        }
      />
      <div className={classes.page}>
        <FormHeader
          title={intl.formatMessage({
            id: "edit_fridge",
            defaultMessage: "Edit Fridge",
          })}
          links={[]}
          hasBack={true}
          hasSave={isDirty}
          onClickSave={(event) => {
            if (submit) submit(event);
          }}
          onClickBack={() => {
            history.push("/fridges");
          }}
        />
        <div>
          {isLoading || isChannelsLoading ? (
            <Typography>
              <FormattedMessage id="loading" defaultMessage="Loading..." />
            </Typography>
          ) : (
            <FridgeForm
              channelsData={channelsData}
              onSubmit={onSubmit}
              setSubmit={changeSubmit}
              initialValues={initialValues}
              getFormState={setFormState}
              disabledFields={["account"]}
            />
          )}
        </div>
      </div>
      <Snackbar
        open={isAlertOpen}
        autoHideDuration={6000}
        onClose={() => setIsAlertOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="success">
          <FormattedMessage
            id="edit_success"
            defaultMessage="Edited successfully"
          />
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default Edit;
