import { FC, useState, useEffect } from "react";

import { Link } from "react-router-dom";

import { useIntl } from "react-intl";

import { AppBar, Toolbar, Theme, IconButton, Hidden } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";

import { SearchField, colors, muiTheme } from "@mb-pro-ui/components";

import { useDebounce } from "../../hooks/useDebounce";

const useStyles = makeStyles((theme: Theme) => ({
  search_bar: {
    height: "8vh",
    backgroundColor: colors.highlight,
    display: "flex",
    justifyContent: "center",
  },

  add_button: {
    marginLeft: "auto",
  },
  add_icon: {
    fontSize: "200%",
    color: theme.palette.common.white,
  },
  inputs: {
    marginRight: "2%",
    color: theme.palette.common.white,
  },
}));

interface SearchBarProps {
  handleSearch(account: string, name: string): void;
  defaultAccount: string;
  defaultName: string;
}

const SearchBar: FC<SearchBarProps> = ({
  handleSearch,
  defaultAccount,
  defaultName,
}) => {
  const classes = useStyles(muiTheme);
  const intl = useIntl();

  const [account, setAccount] = useState(defaultAccount);
  let debouncedAccount = useDebounce(account, 500);

  const [name, setName] = useState(defaultName);
  let debouncedName = useDebounce(name, 500);

  useEffect(() => {
    if (
      debouncedAccount ||
      debouncedAccount === "" ||
      debouncedName ||
      debouncedName === ""
    ) {
      handleSearch(debouncedAccount, debouncedName);
    }
  }, [debouncedAccount, debouncedName, handleSearch]);

  return (
    <AppBar position="static" className={classes.search_bar}>
      <Toolbar>
        <SearchField
          size={"small"}
          placeholder={intl.formatMessage({
            id: "account",
            defaultMessage: "Account",
          })}
          value={account}
          onChange={(e) => setAccount(e.target.value)}
          className={classes.inputs}
        />

        <SearchField
          size={"small"}
          placeholder={intl.formatMessage({
            id: "name",
            defaultMessage: "Name",
          })}
          value={name}
          onChange={(e) => setName(e.target.value)}
          className={classes.inputs}
        />

        <Hidden xsDown>
          <Link to="/fridges/new">
            <IconButton className={classes.add_button}>
              <AddIcon className={classes.add_icon} />
            </IconButton>
          </Link>
        </Hidden>
      </Toolbar>
    </AppBar>
  );
};

export default SearchBar;
