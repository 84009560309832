import { useMemo, useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";

export const useSearchParams = (): [
  URLSearchParams,
  (values: Record<string, string | null>) => void
] => {
  const location = useLocation();
  const history = useHistory();

  const searchParams = useMemo(() => {
    return new URLSearchParams(location.search ?? "");
  }, [location.search]);

  const setSearchParams = useCallback(
    (values) => {
      for (const [key, value] of Object.entries(values)) {
        if (value && typeof value === "string") {
          searchParams.set(key, value);
        } else {
          searchParams.delete(key);
        }
      }

      searchParams.sort();
      const string = searchParams.toString();
      history.replace({ search: string ? `?${string}` : "" });
    },
    [searchParams, history]
  );
  return [searchParams, setSearchParams];
};
