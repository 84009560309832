import { useCallback, useState } from "react";

import { FormattedMessage } from "react-intl";

import { Container, Grid, Typography } from "@material-ui/core";

import { makeStyles } from "@material-ui/core/styles";

import { useQuery } from "react-query";

import { Header } from "@mb-pro-ui/components";

import { searchFridges } from "../utils/api";

import SearchBar from "./dashboard/SearchBar";
import Fridge from "./dashboard/Fridge";

import { useSearchParams } from "../hooks/useSearchParams";

const useStyles = makeStyles(() => ({
  container: {
    minHeight: "fit-content(92vh)",
    paddingTop: "2vh",
  },
  grid: {
    display: "flex",

    alignItems: "center",
    justifyContent: "space-around",
  },
}));

const DashBoard = (): JSX.Element => {
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();

  const [account, setAccount] = useState(searchParams.get("acc") ?? "");
  const [name, setName] = useState(searchParams.get("name") ?? "");

  const { data, refetch, isLoading } = useQuery(
    ["fridges", account, name],
    () => searchFridges(account, name)
  );

  const handleSearch = useCallback(
    (acc: string, name: string) => {
      setAccount(acc);
      setName(name);
      setSearchParams({ name, acc });
    },
    [setAccount, setName, setSearchParams]
  );

  return (
    <>
      <Header position="relative" isLoading={isLoading} />
      <SearchBar
        handleSearch={handleSearch}
        defaultAccount={account}
        defaultName={name}
      />
      {isLoading ? (
        <Typography variant="subtitle1">
          <FormattedMessage id="loading" defaultMessage="Loading..." />
        </Typography>
      ) : (
        <Container className={classes.container} maxWidth="xl">
          <Grid className={classes.grid} container spacing={3}>
            {data ? (
              data.map((fridge) => (
                <Fridge
                  id={fridge.id}
                  key={fridge.account}
                  account={fridge.account}
                  name={fridge.name}
                  channels={fridge.channels}
                  refetch={refetch}
                ></Fridge>
              ))
            ) : (
              <Typography variant="subtitle1">
                <FormattedMessage
                  id="nofridge"
                  defaultMessage="No fridges found"
                />
              </Typography>
            )}
          </Grid>
        </Container>
      )}
    </>
  );
};

export default DashBoard;
