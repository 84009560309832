import { FC, useState } from "react";

import { Link } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";

import { QueryObserverResult, RefetchOptions } from "react-query";

import {
  List,
  ListItem,
  Grid,
  Theme,
  IconButton,
  Hidden,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { Button, colors, muiTheme } from "@mb-pro-ui/components";

import ErrorIcon from "@material-ui/icons/Error";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";

import FridgeChart from "./FridgeChart";

import { FridgeWChannels, formatUnit, deleteFridge } from "../../utils/api";

const useStyles = makeStyles((theme: Theme) => ({
  grid_line: {
    backgroundColor: colors.background,
    boxShadow: " 0 4px 8px 0 rgba(0,0,0,0.2)",
    transition: " 0.3s",
    margin: "0",
    marginTop: "1%",
  },
  grid_item_first: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "row",
      justifyContent: "space-between",
    },
    alignItems: "center",
  },
  grid_first_h2: {
    fontSize: "2rem",
    marginBottom: "5%",
  },
  grid_seleced_button: {
    fontWeight: "bolder",
    backgroundColor: theme.palette.grey[300],
  },
  grid_first_h3: {
    fontSize: "1.5rem",
  },
  grid_buttons: {
    width: "50%",
    display: "flex",
    justifyContent: "space-evenly",
    [theme.breakpoints.down("xs")]: {
      display: "block",
      width: "fit-content",
    },
    [theme.breakpoints.up("lg")]: {
      flexDirection: "column",
      alignItems: "flex-end",
      height: "100%",
      width: "100%",
    },
  },
  edit_button: {
    marginTop: "5%",
    color: theme.palette.warning.main,
  },
  edit_icon: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
    },
  },
  delete_button: {
    marginTop: "5%",
    color: theme.palette.error.main,
  },
  delete_icon: {
    [theme.breakpoints.up("lg")]: {
      fontSize: "1.8rem",
    },
  },

  grid_second: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  grid_second_p: {
    margin: "0",
    alignSelf: "start",
    marginLeft: "25%",
    [theme.breakpoints.between("xs", "md")]: {
      marginLeft: "15%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "10%",
      marginLeft: "10%",
    },
  },
  error_icon: {
    color: theme.palette.error.main,
    fontSize: "1.8rem",
    paddingLeft: "2%",
  },
}));

interface FridgeProps extends FridgeWChannels {
  refetch: (
    options?: RefetchOptions | undefined
  ) => Promise<QueryObserverResult<FridgeWChannels[], unknown>>;
}

const Fridge: FC<FridgeProps> = ({
  id,
  account,
  name,
  channels,
  refetch,
}): JSX.Element => {
  const [display, setDisplay] = useState(0);

  const classes = useStyles(muiTheme);
  const intl = useIntl();

  return (
    <Grid item container spacing={1} className={classes.grid_line}>
      <Grid className={classes.grid_item_first} xs={12} sm={6} lg={3} item>
        <Link to={`/fridges/${id}`}>
          <div>
            <h2 className={classes.grid_first_h2}>{account}</h2>
            <h3 className={classes.grid_first_h3}>{name}</h3>
          </div>
        </Link>
        <Hidden lgUp>
          <div className={classes.grid_buttons}>
            <Link to={`/fridges/edit/${id}`}>
              <IconButton className={classes.edit_button}>
                <EditIcon className={classes.edit_icon} />
              </IconButton>
            </Link>
            <Link to={"/delete"}>
              <IconButton className={classes.delete_button}>
                <DeleteIcon />
              </IconButton>
            </Link>
          </div>
        </Hidden>
      </Grid>
      {channels.length !== 0 ? (
        <>
          <Grid className={classes.grid_second} xs={12} sm={6} lg={4} item>
            <Typography className={classes.grid_second_p}>
              <FormattedMessage id="channels" defaultMessage="Channels" />
              {": "}
            </Typography>
            <List component="div">
              {channels.map((channel, i) => (
                <ListItem key={channel.name}>
                  <Button
                    mode="secondary"
                    onClick={() => setDisplay(i)}
                    className={
                      display === i ? classes.grid_seleced_button : undefined
                    }
                  >
                    {channel.name}:{" "}
                    {formatUnit(
                      channel.data_channels.last_value,
                      channel.unit,
                      intl
                    )}
                  </Button>
                  {channel.data_channels?.alert ? (
                    <ErrorIcon className={classes.error_icon} />
                  ) : null}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid xs={12} sm={12} lg={4} item>
            <FridgeChart
              channels={channels.map((channel) => ({
                name,
                meas: channel.data_channels.meas,
              }))}
              display={display}
            />
          </Grid>
        </>
      ) : (
        <Grid className={classes.grid_second} xs={12} sm={6} lg={8} item>
          <Typography className={classes.grid_second_p}>
            <FormattedMessage
              id="nochannels"
              defaultMessage="Channels not found"
            />
          </Typography>
        </Grid>
      )}
      <Hidden mdDown>
        <Grid xs={12} sm={12} lg={1} item>
          <div className={classes.grid_buttons}>
            <Link to={`/fridges/edit/${id}`}>
              <IconButton className={classes.edit_button}>
                <EditIcon className={classes.edit_icon} />
              </IconButton>
            </Link>
            <Link to={"/delete"}>
              <IconButton
                className={classes.delete_button}
                onClick={async () => {
                  await deleteFridge(id);
                  refetch();
                }}
              >
                <DeleteIcon className={classes.delete_icon} />
              </IconButton>
            </Link>
          </div>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default Fridge;
