import { FC, useRef, useState, useMemo } from "react";

import { FormHeader, muiTheme } from "@mb-pro-ui/components";

import { useHistory, Prompt } from "react-router-dom";

import { useIntl, FormattedMessage } from "react-intl";

import { FormState } from "final-form";
import { AnyObject } from "react-final-form";

import { useQuery } from "react-query";

import { makeStyles, Theme, Typography, Snackbar } from "@material-ui/core";
import MuiAlert from "@material-ui/lab/Alert";

import FridgeForm from "./FridgeForm/FridgeForm";

import { getChannels, saveNewFridge, AddedFridge } from "../utils/api";

interface Values {
  name?: string;
  account?: string;
  newchannels?: {
    name?: string;
    unit?: string;
    channel_ref?: number;
  }[];
}

const useStyles = makeStyles((theme: Theme) => ({
  page: {
    width: "100%",
    display: "flex",
    alignItems: "stretch",
    minHeight: "100vh",
    backgroundColor: theme.palette.background.default,
  },
}));

const NewFridge: FC = () => {
  let submit:
    | ((
        event?: Partial<
          Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">
        >
      ) => Promise<AnyObject | undefined> | undefined | void)
    | undefined = undefined;

  const history = useHistory();

  const classes = useStyles(muiTheme);
  const intl = useIntl();

  const [snackOpen, setSnackOpen] = useState(false);
  const [snackMsg, setSnackMsg] = useState("");

  const formState = useRef<
    FormState<Record<string, any>, Partial<Record<string, any>>>
  >();
  const [isDirty, setIsDirty] = useState(false);

  const setFormState = (
    newFormState: FormState<Record<string, any>, Partial<Record<string, any>>>
  ) => {
    if (formState.current?.dirty !== newFormState.dirty) {
      setIsDirty(newFormState.dirty);
    }
    formState.current = newFormState;
  };

  const onSubmit = async (values: Values) => {
    let errors: Values = { newchannels: [] };

    if (!values.name) errors.name = "Required";
    if (!values.account) errors.account = "Required";
    if (!values.account?.match(/^[A-Fa-f0-9]+$/))
      errors.account = "4 Uppercase character required";

    values.newchannels?.forEach((channel) => {
      let chanelerror: {
        name?: string;
        unit?: string;
        channel_ref?: number;
      } = {};
      if (!channel?.name) {
        chanelerror.name = "Required";
      }
      if (!channel?.unit) {
        chanelerror.unit = "Required";
      }
      if (!channel?.channel_ref) {
        chanelerror.channel_ref = -1;
      }

      errors.newchannels?.push(chanelerror);
    });

    if (
      Object.keys(errors).length === 1 &&
      errors.newchannels?.every((e) => Object.keys(e).length === 0)
    ) {
      const [fridgeId, saveMessage] = await saveNewFridge(
        values as AddedFridge,
        intl
      );

      if (fridgeId !== -1) {
        setIsDirty(false);
        history.push(`/fridges/edit/${fridgeId}`);
      }
      if (saveMessage) {
        setSnackMsg(saveMessage);
        setSnackOpen(true);
      }
    }
    return errors;
  };

  const changeSubmit = (
    newSubmit:
      | ((
          event?: Partial<
            Pick<React.SyntheticEvent, "preventDefault" | "stopPropagation">
          >
        ) => Promise<AnyObject | undefined> | undefined | void)
      | undefined
  ) => {
    submit = newSubmit;
  };

  const { data: channelsData, isLoading } = useQuery(
    "channels",
    () => getChannels(),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    }
  );

  const initialValues = useMemo(
    () => ({
      newchannels: [{}, {}],
    }),
    []
  );

  return (
    <>
      <div className={classes.page}>
        <Prompt
          when={isDirty}
          message={() =>
            intl.formatMessage({
              id: "new_message",
              defaultMessage: "You have unsaved data",
            })
          }
        />
        <FormHeader
          title="New Fridge"
          links={[]}
          hasBack={true}
          hasSave={isDirty}
          onClickSave={(e) => {
            if (submit) submit(e);
          }}
          onClickBack={() => {
            history.push("/fridges");
          }}
        />
        {isLoading ? (
          <Typography>
            <FormattedMessage id="loading" defaultMessage="Loading..." />
          </Typography>
        ) : (
          <div>
            <FridgeForm
              channelsData={channelsData}
              setSubmit={changeSubmit}
              onSubmit={onSubmit}
              initialValues={initialValues}
              getFormState={setFormState}
            />
          </div>
        )}
      </div>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => setSnackOpen(false)}
      >
        <MuiAlert elevation={6} variant="filled" severity="error">
          {snackMsg}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default NewFridge;
