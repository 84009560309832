const capitalize = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getTimeTick = (date: Date, lang: string) => {
  if (date.getDay() === 1 && date.getHours() === 0) {
    return capitalize(date.toLocaleString(lang, { month: "long" }));
  }
  if (
    date.getHours() === 0 &&
    date.getMinutes() === 0 &&
    date.getSeconds() === 0
  ) {
    return `${capitalize(
      date.toLocaleString(lang, {
        weekday: "long",
      })
    )} ${date.getDate()}`;
  }
  if (date.getSeconds() === 0) {
    return `${date.getHours() < 10 ? "0" + date.getHours() : date.getHours()}:${
      date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()
    }`;
  }

  return date.toLocaleString();
};
